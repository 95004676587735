<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <div>
    <!-- Miscellaneous Charts -->
    <b-row class="match-height">
      
      <b-col
        lg="12"
        cols="12"
      >
        <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="mr-25 mb-0">
        
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      
        <b-col
            md="6"
            cols="12"
        >
            <b-card>
                <b-card-title class="mb-1">
                    Students Enrolled in Courses
                </b-card-title>
                
            
                <chartjs-component-polar-area-chart
                    :height="350"
                    :data="polarChart.data"
                    :options="polarChart.options"
                    ref="polar"
                />
                
            </b-card>
            
        
        </b-col>
        <b-col
            md="6"
            cols="12"
        >
            <b-card
                no-body
                class="card-developer-meetup"
            >
                <div class="bg-light-primary rounded-top text-center">
                <b-img
                    :src="require('@/assets/images/illustration/email.svg')"
                    alt="Meeting Pic"
                    height="170"
                />
                </div>
            <b-card-body v-if="latestEvent">
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                <h6 class="mb-0">
                    
                    {{moment(latestEvent.start_date).format('ddd')}}
                </h6>
                <h3 class="mb-0">
                    {{moment(latestEvent.start_date).format('DD')}}
                </h3>
                </div>
                <div class="my-auto">
                <b-card-title class="mb-25">
                    {{latestEvent.title}}
                </b-card-title>
                <!--<b-card-text class="mb-0">
                    Meet world popular developers
                </b-card-text>-->
                    </div>
                </div>
                    <b-card-text class="mb-2">
                        {{latestEvent.description}}
                    </b-card-text>
      <!--/ metting header -->

      <!-- media -->
                    <b-media
                        no-body
                        class="mb-2"
                    >
                        <b-media-aside class="mr-1">
                        <b-avatar
                            rounded
                            variant="light-primary"
                            size="34"
                        >
                            <feather-icon
                            icon="CalendarIcon"
                            size="18"
                            />
                        </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                        <h6 class="mb-0">
                            {{moment(latestEvent.start_date).format('ddd, MMMM DD, YYYY')}}
                        </h6>
                        <small>{{moment(latestEvent.start_date).format('hh:mm:a')}} to {{moment(latestEvent.end_date).format('hh:mm:a')}}</small>
                        </b-media-body>
                    </b-media>
                        <b-media
                        no-body
                        class="mb-2"
                    >
                        <b-media-aside class="mr-1">
                        <b-avatar
                            rounded
                            variant="light-primary"
                            size="34"
                        >
                            <feather-icon
                            icon="MapPinIcon"
                            size="18"
                            />
                        </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                        <h6 class="mb-0">
                            {{latestEvent.location}}
                        </h6>
                        <small></small>
                        </b-media-body>
                    </b-media>
      <!-- avatar group -->
                </b-card-body>
                <b-card-body v-else>
                    <h3>No Event</h3>
                </b-card-body>
            </b-card>
        </b-col>
        
    </b-row>
  </div>
  </b-overlay>
</template>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>

<script>

import { BRow, BCol, BCard, BDropdown, BOverlay, BDropdownItem, BButton, BCardHeader, BCardTitle, BCardText, BCardBody, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'
import DashboardService  from '@/services/dashboard.service.js';
import ChartjsComponentPolarAreaChart from './charts-components/ChartjsComponentPolarAreaChart.vue'
import moment from 'moment'

// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
const apexChartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}
export default {
  components: {
    BRow,
    BCol,
    BCard, BCardHeader, VueApexCharts,
    BDropdown,
    BDropdownItem,
    BButton, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
    BImg,
    AppEchartDoughnut,
    flatPickr,
    ChartjsComponentBarChart,
    BCardSubTitle,
    ChartjsComponentPolarAreaChart,
    BOverlay
  },
  directives: {
    Ripple,
    moment
  },
  data() {
    return {
      
        polarChart: {
            options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                position: 'right',
                labels: {
                usePointStyle: true,
                padding: 25,
                boxWidth: 10,
                fontColor: chartColors.labelColor,
                },
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark,
            },
            scale: {
                scaleShowLine: true,
                scaleLineWidth: 1,
                ticks: {
                display: false,
                fontColor: chartColors.labelColor,
                },
                reverse: false,
                gridLines: {
                display: false,
                },
            },
            animation: {
                animateRotate: false,
            },
            },
            data: {
                labels: [],
            
                datasets: [
                    {
                    label: 'Population (millions)',
                    backgroundColor: [
                        chartColors.primaryColorShade,
                        chartColors.warningColorShade,
                        $themeColors.primary,
                        chartColors.infoColorShade,
                        chartColors.greyColor,
                        chartColors.successColorShade,
                    ],
                    data: [],
                    borderWidth: 0,
                    },
                ],
            },
        },
        
        latestEvent: {},
        showOverlay: false,
        role: '',
        moment: moment,
      statisticsItems: [
        {
          icon: 'UserIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'Students',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BookOpenIcon',
          color: 'light-info',
          title: '',
          subtitle: 'Courses',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'Events',
          customClass: 'mb-2 mb-sm-0',
        },
        
      ],
      
      revenue_report: {},
    latestBarChart: {
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: chartColors.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
            //   stepSize: 100,
              min: 0,
            //   max: 400,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
    },
    
    }
  },
  methods: {
    getStats() {
        this.showOverlay = true;
        DashboardService.adminDashboard().then(response => {
            this.statisticsItems[0].title = response.data.data.activeStudents
            this.statisticsItems[1].title = response.data.data.totalCourses
            this.statisticsItems[2].title = response.data.data.totalEvents
            
            this.latestEvent = response.data.data.getLatestEvent
            this.polarChart.data.datasets[0].data = response.data.data.courseWiseStudent.map(({ value }) => value)
            this.polarChart.data.labels = response.data.data.courseWiseStudent.map(({ name }) => name)
            //this.latestBarChart.data.datasets[0].data = response.data.data.monthlyRevenue.map(({ value }) => value)
            //this.latestBarChart.data.labels = response.data.data.monthlyRevenue.map(({ name }) => name)
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
  },
  created() {
    this.getStats();
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
